import React from 'react';
import { Page, PageContent, PageHeader } from 'grommet';

const Account = () => (
    <Page>
        <PageContent>
            <PageHeader title="Your Account" />
            {/* Content for the account page */}
        </PageContent>
    </Page>
);

export default Account;
