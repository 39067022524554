import React from "react";
import { Header, Box, Button, Text } from "grommet";
import { Logout } from "grommet-icons";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation
import styled from "styled-components";

// Styled header with the appropriate background color
const StyledHeader = styled(Header)`
  background-color: #1c1c1c; // Dark top menu background color
  border-bottom: none;
  padding: 0 2rem;
  box-shadow: none;
`;

// App name styled text
const AppName = styled(Text)`
  font-weight: normal;
  color: #ffffff; // Default white text color
  letter-spacing: 1.2px;
`;

// Logout button with transparent background and hover effect
const LogoutButton = styled(Button)`
  border-radius: 50px;
  padding: 0.5rem 1rem;
  background-color: transparent;
  color: #ffffff; // White text
  font-weight: normal;
  border: none;
  transition: none;
  box-shadow: none;
  &:hover {
    background-color: #444444; // Slight dark background on hover
    box-shadow: none;
  }
`;

// Navigation button with transparent background and similar hover effect
const NavButton = styled(Button)`
  border-radius: 50px;
  padding: 0.5rem 1rem;
  background-color: transparent;
  color: #ffffff; // White text color for nav buttons
  font-weight: normal;
  border: none;
  transition: none;
  box-shadow: none;
  &:hover {
    background-color: #444444; // Slight dark background on hover
    box-shadow: none;
  }
`;

const AppBar = ({ setAuth}) => {
    const navigate = useNavigate();
    const location = useLocation(); // Get the current location

    const handleLogout = () => {
        setAuth(false);
        localStorage.removeItem('isLoggedIn');
        navigate('/login');
    };

    const handlePatientAdmin = () => {
        navigate('/admin'); // Adjust the path as needed
    };

    const handleDashboard = () => {
        navigate('/'); // Adjust the path as needed
    };


    // Conditionally render the two buttons only if we are on the dashboard (i.e., "/")
    const isDashboardPage = location.pathname === '/';

    return (
        <StyledHeader justify="between">
            <AppName>Agama Health</AppName>
            <NavButton label="Dashboard" onClick={handleDashboard} />
            <NavButton label="Patient Admin" onClick={handlePatientAdmin} />

            {/* Centered box for buttons (conditionally shown) */}
            <Box direction="row" align="center" justify="center" gap="medium" flex="grow">

            </Box>

            {/* Logout button */}
            <LogoutButton icon={<Logout />} label="Logout" onClick={handleLogout} />
        </StyledHeader>
    );
};

export default AppBar;
