import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            <p>&copy; 2024 Agama Health All Rights Reserved. <a href="https://agamanetworks.com/" className="footer-link">Visit our website</a></p>
        </footer>
    );
};

export default Footer;
