// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Global CSS for placeholder text */
input::placeholder {
    color: #ffffff !important; /* White placeholder text */
    font-style: italic; /* Italic placeholder text */
}

/* Globally reset focus outline for all elements */
*:focus {
    outline: none !important;
    box-shadow: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/global.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;IACI,yBAAyB,EAAE,2BAA2B;IACtD,kBAAkB,EAAE,4BAA4B;AACpD;;AAEA,kDAAkD;AAClD;IACI,wBAAwB;IACxB,2BAA2B;AAC/B","sourcesContent":["/* Global CSS for placeholder text */\ninput::placeholder {\n    color: #ffffff !important; /* White placeholder text */\n    font-style: italic; /* Italic placeholder text */\n}\n\n/* Globally reset focus outline for all elements */\n*:focus {\n    outline: none !important;\n    box-shadow: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
