import React from 'react';
import { ChartContainer, Iframe } from './StyledComponents';

const Chart = ({ chart, index, handleIframeLoad, dashboardCharts, onDemandCharts }) => {
    const isHidden = (!dashboardCharts && chart.Record.chartDescription !== 'dynamic') ||
        (!onDemandCharts && chart.Record.chartDescription === 'dynamic');

    return (
        <ChartContainer key={index} style={{ display: isHidden ? 'none' : 'block' }}>
            <Iframe
                srcDoc={chart.Record.chartHTML}
                title={`chart-${index}`}
                onLoad={handleIframeLoad}
                data-dashboard-chart
            />
        </ChartContainer>
    );
};

export default Chart;
